import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"
import Header from "./header"

const CustomNavbar = ({ pageInfo, navbar, sitePage, homePage }) => {
  console.log(pageInfo)
  return (
    <>
      <Navbar variant="dark" expand="lg" id={sitePage} className={navbar}>
        {/* <Container> */}
        <div className="wrapper-navbar">
          <Header homePage={homePage}/>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="/">
                  Home
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/about-us" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="about-us">
                  About us
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/blog" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="blog">
                  Blog
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/news" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="news">
                  News
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/capabilities" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="capabilities">
                  Capabilities
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/portfolio" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="portfolio">
                  Portfolio
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/services" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="services">
                  Services
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/work-with-us" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="work-with-us">
                  Work with us
                </Nav.Link>
              </Link>
            </Nav>
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/contact-us" className="link-no-style" activeClassName="active">
                <Nav.Link as="span" eventKey="contact-us">
                  Contact us
                </Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>
          {/* </Container> */}
        </div>
      </Navbar>
    </>
  )
}

export default CustomNavbar
