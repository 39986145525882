import React, { Component } from 'react';

class BackgroundImages extends Component {
  render(){
    return (
      <div className="bg-image">
        <div style={this.props.style} className="bgImage" />
        {this.props.children}
      </div>
    );
  }
}

export default BackgroundImages
