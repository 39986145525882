import React, { Component } from "react";
import { Image } from "react-bootstrap";

class TabImage extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="image-tab">
          <Image alt="900x500" className="image1 lazyload" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" data-src={this.props.image1} />
        </div>
        <div className="content-tab">
          <p className="title-service">{this.props.title1}</p>
          <p className="title-service">{this.props.title2}</p>
          <div className="clear" />
          <p className="p-service">{this.props.content1}</p>
          <p className="p-service">{this.props.content2}</p>
        </div>
      </div>
    );
  }
}

export default TabImage;
