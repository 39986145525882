import React, {Component} from "react"

import { Image } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class Header extends Component {
  render () {
    let title;
    if (this.props.homePage) {
      title = (<h1 className="text-header"><span className="bg-text-header">a professional full stack</span><br/> <span className="space" /><span className="bg-text-header">software service company</span></h1>)
    } else {
      title = (<div/>)
    }
    return (
      <header
        style={{
          background: `transparent`,
        }}
      >
        <div
          style={{
            margin: `0 auto`,
          }}
        >
          <span style={{ margin: 0 }}>
            <OutboundLink
              href="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <Image src={require("../images/logo.png")} alt="Opentechiz" className="image-logo"  />
              <Image src={require("../images/logo-white.png")} alt="Opentechiz" className="image-logo-white" />
            </OutboundLink>
          </span>
          {title}
        </div>
      </header>
    )
  }
}

export default Header;
