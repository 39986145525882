import React, { Component } from 'react'
import {
  Image,
  Col
} from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import { OutboundLink } from 'gatsby-plugin-gtag'

class Footer extends Component {
  render(){
    return (
      <div className="footer">
        <Fade>
          <Col sm={12} lg={12} md={12}>
            <OutboundLink href="/">
              <Image
                className="logo-footer lazyload"
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require ("../../images/logo-white.png")}
              />
            </OutboundLink>
          </Col>
          <Col sm={12} lg={12} md={12} className="box-menu-footer">
            <Col sm={12} lg={12} md={12} className="social-footer">
              <Col sm={8} lg={7} md={7} xl={6} className="phone-email">
                <div className="text-phone-email">
                  <span className="icon-phone"></span>
                  <span>Phone: (+84) 834566769</span>
                </div>
                <div className="text-phone-email">
                  <span className="icon-email"></span>
                  <span>Email: support@opentechiz.com</span>
                </div>
              </Col>
              <Col sm={4} lg={5} md={5} xl={6} className="icon-footer">
                <OutboundLink href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                  <Image
                    className="logo-social lazyload"
                    alt="900x500"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                    data-src={require ("../../images/png/facebook-logo-button.png")}
                  />
                </OutboundLink>
                <OutboundLink href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                  <Image
                    className="logo-social lazyload"
                    alt="900x500"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                    data-src={require ("../../images/png/twitter-logo-button.png")}
                  />
                </OutboundLink>
                <OutboundLink href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                  <Image
                    className="logo-social lazyload"
                    alt="900x500"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                    data-src={require ("../../images/png/google-plus-logo-button.png")}
                  />
                </OutboundLink>
              </Col>
            </Col>
            <Col sm={12} lg={12} md={12} className="copyright">
              <div className="text-copyright">
                Open Techiz Business Technology reg code 0108238216 - Born with <span className="icon-heart"></span> since 2007
              </div>
            </Col>
          </Col>
        </Fade>
      </div>
    );
  }
};

export default Footer;
