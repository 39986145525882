/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from "react"
import { Container, Row } from "react-bootstrap"
import Navbar from "./navBar"
import { Footer } from "../shared"

class Layout extends Component {
  constructor(props) {
      super(props);
      this.state = {};
      this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
      this.setState({scroll: window.scrollY});
  }

  componentDidMount() {
      const el = document.querySelector('nav');
      this.setState({top: el.offsetTop, height: el.offsetHeight});
      window.addEventListener('scroll', this.handleScroll);
  }

  render () {
    return (
      <div>
        <Container fluid className="px-0 main">
          <Row noGutters className="justify-content-center">
          </Row>
          <Navbar homePage={this.props.homePage} pageInfo={this.props.pageInfo} sitePage={this.props.sitePage} navbar={this.state.scroll > this.state.top ? "fixed-nav" : ""}  />
          <main>{this.props.children}</main>
        </Container>
        <Footer />
      </div>
    )
  }
}

export default Layout;
